import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './SuccessText.css';

const SuccessText = ({ text }) => {
  return (
    <div className="SuccessText-container">
      <div className="SuccessText-icon">
        <FontAwesomeIcon icon="check" />
      </div>
      {text}
    </div>
  )
}

export default SuccessText;
