import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import '@fortawesome/fontawesome-svg-core/styles.css';
import RandomWarmupButton from './warmups/RandomWarmupButton';
import './header.css';

const Header = ({ siteTitle, hideWarmupButton, subtleButton }) => (
  <header
    className="Header-container"
  >
    <div
      className="Header-container-inner"
    >
      <h1 style={{ margin: 0, fontFamily: 'Termina' }}>
        <Link
          to="/"
          style={{
            color: `#222`,
            textDecoration: `none`,
          }}
        >
          {siteTitle}
        </Link>
      </h1>
      {hideWarmupButton ? null : <div className="Header-cta"><RandomWarmupButton subtleButton={subtleButton} /></div>}
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
