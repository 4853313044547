/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Helmet from "react-helmet"
import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowDown, faCheck, faCircle, faChevronDown, faArrowRight, faCoffee } from '@fortawesome/free-solid-svg-icons'


import Header from "./header"
import "./layout.css"

library.add(faChevronDown, faCheck, faCircle, faArrowDown, faArrowRight, faCoffee)

const Layout = ({ children, location, hideWarmupButton, subtleButton }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/kiw7tij.css"/>
      </Helmet>
      <Header subtleButton={subtleButton} hideWarmupButton={hideWarmupButton} siteTitle={data.site.siteMetadata.title} />
      <div
      >
        <main style={{ minHeight: "100%" }}>{children}</main>
        <footer>

        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
