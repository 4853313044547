import React from "react"
import { Link, useStaticQuery} from "gatsby"
import "./RandomWarmupButton.css"

const RandomWarmupButton = ({ groupSize, subtleButton }) => {

  const data = useStaticQuery(graphql`
    query RandomWarmupQuery {
      swapi {
        warmups {
          id
          title
          slug
          description
          groupSizes
        }
      }
    }
  `);
  const filterWarmups = (warmups, groupSize) => {
    return warmups.filter((warmup) => {
      return (
        !groupSize ||
        !warmup.groupSizes ||
        warmup.groupSizes.length === 0 ||
        warmup.groupSizes.filter((size => size === groupSize)).length > 0
      )
    })
  }
  let warmups = filterWarmups(data.swapi.warmups, groupSize);

  return (
    <div className="RandomWarmupButton-container">
      <Link
        className="RandomWarmupButton-button"
        style={subtleButton ? { background: "var(--main-color-light)", borderColor: "transparent" } : {}}
        to={`warmups/${warmups[Math.floor(Math.random() * warmups.length)].slug}`}
      >
        Warm Up!
      </Link>
    </div>
  )
}

export default RandomWarmupButton
