import React from "react";
import { useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import "./WeirdPics.css";
import RandomWarmupButton from '../warmups/RandomWarmupButton';

const WeirdPics = ({ warmup, isHome }) => {

  const query = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "sketch-01.png" }) {
        ...weirdPic
      },
      mobile: file(relativePath: { eq: "sketch-02.png" }) {
        ...weirdPic2
      }
    }
  `);

  return (
    <div className={`WeirdPics-container ${isHome ? 'WeirdPics-container-home' : ''}`}>
      <div className="WeirdPics-container-inner">
        <Img className="WeirdPics-desktop" style={{ width: "100%", marginTop: isHome ? "-40px" : "20px" }} fluid={query.desktop.childImageSharp.fluid} />
        <Img className="WeirdPics-mobile" style={{ width: "100%", marginTop: isHome ? "-40px" : "20px" }} fluid={query.mobile.childImageSharp.fluid} />
      </div>
      <div className="WeirdPics-footer">
        <div className="WeirdPics-footer-inner" style={{ paddingBottom: 40 }}>
          <div className="WeirdPics-footer-logo" style={{ fontFamily: 'Termina', fontWeight: 700, fontSize: 28 }}><Link style={{ color: "#fff", textDecoration: "none" }} to="/">Warmupz</Link></div>
          <ul className="WeirdPics-footer-links">
            <li><Link to="/list">View all warm ups</Link></li>
            <li><Link to="/submit-warmup">Share a warm up</Link></li>
            <li><a href="mailto: dylan@tsh.works">Contact Us</a></li>
          </ul>
          <RandomWarmupButton />
        </div>
        <div className="WeirdPics-footer-inner" style={{ justifyContent: "center"}}>
          <div className="WeirdPics-copyright">
            © 2020 TSH | Made in San Francisco
          </div>
        </div>
      </div>
    </div>
  )
}

export const weirdPic = graphql`
  fragment weirdPic on File {
    childImageSharp {
      fluid(maxWidth: 2144, maxHeight: 913) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const weirdPic2 = graphql`
  fragment weirdPic2 on File {
    childImageSharp {
      fluid(maxWidth: 1245, maxHeight: 1383) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export default WeirdPics
